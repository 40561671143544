<template>
  <div>
    <div>
      <div>
        <ValidationObserver ref="formCreateUser">
          <div class="flex flex-wrap">
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="firstname" type="text" :field_name="$t('create_user_page.first_name')" rules="required" @blur="generateUsername()" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="lastname" type="text" :field_name="$t('create_user_page.surname')" @blur="generateUsername()" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="email" type="text" :field_name="$t('create_user_page.email')" rules="required|email" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="phone" type="text" :field_name="$t('create_user_page.phone')" rules="required" />
            </div>
          </div>

          <div class="mx-2 border-b border-secondary-400 my-5"></div>
          <div class="flex flex-wrap">
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseInput v-model="username" type="text" :field_name="$t('create_user_page.user_name')" rules="required|alpha_dash" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <div class="flex flex-wrap">
                <div class="w-5/6">
                  <BaseInput v-model="password" :type="passwordInputType" :field_name="$t('create_user_page.password')" rules="required" />
                </div>
                <div class="w-1/12">
                  <button class="mt-9 pl-3" @click.prevent="showPassword">
                    <BaseIcon v-if="passwordInputType == 'password'" icon="eye" class="text-primary-500" />
                    <BaseIcon v-else icon="eye-slash" class="text-primary-500" />
                  </button>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 px-2 py-2">
              <BaseSelect v-model="role" :options="filtered_roles" nameSelector="name" optionSelector="id" :field_name="$t('create_user_page.role')" rules="required" @change="changeUserRole()" />
            </div>
          </div>

          <div class="mx-2 border-b border-secondary-400 my-5" v-if="role != '' && role != 'admin'"></div>
          <div class="flex flex-wrap">
            <div class="w-full md:w-1/3 px-2 py-2" v-if="/^superuser|^user/.test(role) && user.role == 'admin'">
              <BaseSelect v-model="reseller_id" :options="resellers" nameSelector="name" optionSelector="_id" :field_name="$t('create_user_page.reseller')" :rules="/^reseller/.test(role) ? 'required' : ''" @change="changeReseller()" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2" v-if="role == 'reseller'">
              <BaseSelect v-model="reseller_id" :options="resellers" nameSelector="name" optionSelector="_id" :field_name="$t('create_user_page.reseller')" rules="required" />
            </div>
            <div class="w-full md:w-1/3 flex flex-col px-2 py-2" v-if="role == 'superuser' || role == 'user'">
              <div class="w-full text-gray-600 text-sm font-bold pb-2 font-serif">{{ $t("create_user_page.customer") }}<b class="text-red-500 ml-1">*</b></div>
              <div class="w-full">
                <div class="mb-2">
                  <input type="text" v-model.trim="customerSearch" placeholder="Sök" class="bg-gray-100 rounded w-full text-xs text-gray-900 focus:outline-none border-b border-gray-300 transition duration-500 px-2 py-2" />
                </div>
                <div class="max-h-80 overflow-y-scroll border border-gray-200 rounded px-2 py-2">
                  <div v-if="filteredCustomer.length == 0" class="w-full text-xs italic text-gray-500 py-2">{{ $t("create_user_page.no_customer_found") }}</div>
                  <div class="px-2 transition ease-in-out duration-200" v-for="c in filteredCustomer" :key="c._id" v-bind:class="{ 'bg-gray-100': customers_id.indexOf(c.customer_id) > -1 }">
                    <label class="inline-flex items-center mt-2">
                      <input v-if="role == 'superuser'" v-model="customers_id" :value="c.customer_id" type="checkbox" class="form-checkbox h-4 w-4" />
                      <input
                        v-if="role == 'user'"
                        v-model="customers_id[0]"
                        :value="c.customer_id"
                        type="radio"
                        class="form-checkbox h-4 w-4"
                        @change="
                          filterUnitByCustomer();
                          units_id = [];
                        "
                      />
                      <span class="ml-2 text-sm font-sans font-semibold">{{ c.name }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 flex flex-col px-2 py-2" v-if="role == 'user'">
              <div class="w-full text-gray-600 text-sm font-bold pb-2 font-serif">{{ $t("create_user_page.department") }}<b class="text-red-500 ml-1">*</b></div>
              <div class="w-full">
                <div class="mb-2">
                  <input type="text" v-model.trim="unitSearch" :placeholder="$t('create_user_page.search')" class="bg-gray-100 rounded w-full text-xs text-gray-900 focus:outline-none border-b border-gray-300 transition duration-500 px-2 py-2" />
                </div>
                <div class="max-h-80 overflow-y-scroll border border-gray-200 rounded px-2 py-2">
                  <div v-if="filteredUnit.length == 0" class="w-full text-xs italic text-gray-500 py-2">
                    {{ $t("create_user_page.no_business_found") }}
                  </div>
                  <div class="px-2 transition ease-in-out duration-200" v-for="u in filteredUnit" :key="u._id" v-bind:class="{ 'bg-gray-100': units_id.indexOf(u.unit_id) > -1 }">
                    <label class="inline-flex items-center mt-2">
                      <input v-model="units_id" :value="u.unit_id" type="checkbox" class="form-checkbox h-4 w-4" /><span class="ml-2 text-sm font-sans font-semibold">{{ u.name }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/3 px-2 py-2" v-if="role == 'alarm_center'">
              <BaseSelect v-model="alarm_center_id" :options="alarm_centers" nameSelector="name" optionSelector="_id" :field_name="$t('user_role.alarm_center')" rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2" v-if="role == 'seller'">
              <BaseSelect v-model="seller_id" :options="sellers" nameSelector="name" optionSelector="_id" :field_name="$t('user_role.seller')" rules="required" />
            </div>
            <div class="w-full md:w-1/3 px-2 py-2" v-if="role == 'tech'">
              <BaseSelect v-model="tech_id" :options="technicians" nameSelector="name" optionSelector="_id" :field_name="$t('user_role.tech')" rules="required" />
            </div>
          </div>

          <div class="mx-2 border-b border-secondary-400 my-5"></div>
          <div class="flex flex-wrap">
            <div class="w-full px-2">
              <div class="clearfix">
                <button class="btn-blue float-right text-lg py-2 px-5" type="submit" @click.prevent="createUser()">
                  {{ $t("user_actions.save") }}
                  <BaseIcon icon="arrow-right" class="ml-2" />
                </button>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  title() {
    return this.$t("page_titles.create_user");
  },
  data() {
    return {
      filtered_roles: [],
      passwordInputType: "password",
      customerSearch: "",
      unitSearch: "",
      allCustomers: [],
      customers: [],
      allUnits: [],
      units: [],
      customer_units: [],
      alarm_centers: [],
      resellers: [],
      sellers: [],
      technicians: [],
      firstname: "",
      lastname: "",
      username: "",
      email: "",
      phone: "",
      password: "",
      role: "",
      customers_id: [],
      units_id: [],
      alarm_center_id: "",
      reseller_id: "",
      seller_id: "",
      tech_id: "",
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    filteredCustomer() {
      var vm = this;
      let c = _.filter(this.customers, function(o) {
        return o.name.toLowerCase().includes(vm.customerSearch.toLowerCase()) || vm.customers_id.indexOf(o.customer_id) > -1;
      });
      return c;
    },
    filteredUnit() {
      var vm = this;
      let u = _.filter(this.customer_units, function(o) {
        return o.name.toLowerCase().includes(vm.unitSearch.toLowerCase()) || vm.units_id.indexOf(o.unit_id) > -1;
      });
      return u;
    },
    roles() {
      return [
        { id: "admin", name: this.$t("user_role.admin") },
        { id: "superuser", name: this.$t("user_role.customer") },
        { id: "user", name: this.$t("user_role.department") },
        { id: "alarm_center", name: this.$t("user_role.alarm_center") },
        { id: "tech", name: this.$t("user_role.tech") },
        { id: "reseller", name: this.$t("user_role.reseller") },
        { id: "seller", name: this.$t("user_role.seller") },
      ];
    },
  },
  methods: {
    getCustomers() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/customer/`)
        .then((response) => {
          this.allCustomers = response.data.data;
          this.customers = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    getUnits() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/units/`)
        .then((response) => {
          this.allUnits = response.data.data;
          this.units = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    async getAlarmCenters() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/alarm-central`);
        this.alarm_centers = response.data.data;
      } catch (error) {
        this.handleError(error);
      }
    },

    async getResellers() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/reseller`);
        this.resellers = response.data.data;
      } catch (error) {
        this.handleError(error);
      }
    },

    async getSellers() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/sellers`);
        this.sellers = response.data.data;
      } catch (error) {
        this.handleError(error);
      }
    },

    async getTechnicians() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/technicians`);
        this.technicians = response.data.data;
      } catch (error) {
        this.handleError(error);
      }
    },

    createUser() {
      this.$refs.formCreateUser.validate().then((success) => {
        if (!success) {
          return;
        }
        if (this.role == "user" && this.units_id.length == 0) {
          this.handleError(null, this.$t("create_user_page.no_activity"));
          return;
        }
        if (this.role == "superuser" && this.customers_id.length == 0) {
          this.handleError(null, this.$t("create_user_page.no_customer"));
          return;
        }

        if (this.role != "superuser" && this.role != "user") this.customers_id = [];
        if (this.role != "user") {
          this.units_id = [];
        }
        if (this.role != "alarm_center") this.alarm_center_id = "";
        if (this.role != "reseller" && this.role != "superuser" && this.role != "user") this.reseller_id = "";
        if (this.role != "seller") this.seller_id = "";
        if (this.role != "tech") this.tech_id = "";

        let data = {
          firstname: this.firstname,
          lastname: this.lastname,
          username: this.username.toLowerCase(),
          email: this.email.toLowerCase(),
          phone: this.phone,
          password: this.password,
          role: this.role,
          customers_id: this.customers_id,
          units_id: this.units_id,
          alarm_center_id: this.alarm_center_id,
          reseller_id: this.reseller_id,
          seller_id: this.seller_id,
          tech_id: this.tech_id,
        };

        if (this.user.role == "reseller") data.reseller_id = this.user.reseller_id;

        this.axios
          .post(`${process.env.VUE_APP_SERVER_URL}/signup/`, data)
          .then((response) => {
            if (response.data.error) this.handleError(null, response.data.message);

            if (response.data.success) {
              this.handleSuccess(this.$t("create_user_page.new_user_created"));
              this.$router.push("/users");
            }
          })
          .catch((error) => {
            this.handleError(error);
          });
      });
    },

    generateUsername() {
      this.username = this.firstname.slice(0, 3).toLowerCase() + this.lastname.slice(0, 3).toLowerCase();
    },

    showPassword() {
      if (this.passwordInputType === "password") this.passwordInputType = "text";
      else this.passwordInputType = "password";
    },

    filterUnitByCustomer() {
      if (this.customers_id.length == 0) return;

      let c = _.find(this.customers, { customer_id: this.customers_id[0] });
      if (c) this.customer_units = _.filter(this.units, { parent_id: c._id });
      else this.customer_units = [];
    },

    changeUserRole() {
      this.reseller_id = "";
      this.changeReseller();
    },

    changeReseller() {
      if (/^superuser|^user/.test(this.role)) {
        if (this.reseller_id != "") {
          this.getResellerCustomers();
        } else {
          this.customers = _.cloneDeep(this.allCustomers);
          this.units = _.cloneDeep(this.allUnits);
        }

        this.customers_id = [];
        this.units_id = [];
        this.customer_units = [];
      }
    },

    getResellerCustomers() {
      var vm = this;

      this.units = _.filter(this.allUnits, { seller_id: this.reseller_id });
      let customerIDs = _.uniq(_.map(this.units, "parent_id"));
      this.customers = _.filter(this.allCustomers, function(o) {
        return customerIDs.indexOf(o._id) > -1 || o.seller == vm.reseller_id;
      });
    },

    filterRoles() {
      if (this.user.role != "admin")
        this.filtered_roles = _.filter(this.roles, function(o) {
          return o.id == "superuser" || o.id == "user";
        });
      else this.filtered_roles = this.roles;
    },
  },

  created() {
    this.getCustomers();
    this.getUnits();
    if (this.user.role == "admin") this.getAlarmCenters();
    if (this.user.role == "admin") this.getResellers();
    if (this.user.role == "admin") this.getSellers();
    if (this.user.role == "admin") this.getTechnicians();
    this.filterRoles();
    this.setPageTitle(`Skapa nytt inlogg`, "create_user");
  },
  watch: {
    "$i18n.locale"() {
      document.title = this.$t("page_titles.create_user");
    },
  },
};
</script>
